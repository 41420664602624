import PersonIcon from '@mui/icons-material/Person';
import {
  Box, CircularProgress, Paper, Typography, Link
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { config } from "../config/config";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ErrorBanner from "../features/errorBanner/errorBanner";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { EnhancedQueryBar } from '../features/queryBar/EnhancedQueryBar';

import "./Chatbot.css"
import api from "../config/axiosConfigs";
import { storeObjectAndGetHash, storeStringAndGetHash } from '../features/localStorage/localStorageHelper';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import FeedBackStack from '../features/feedBackDialog/feedBackDialog';

const Chatbot = () => {
  // From config get URL and possible LLM list
  const url = config.API_URL;
  const llms = config.LLMS;

  // Configuration Dialog stage and config values
  const [llm, setLLM] = useState(0);
  const [selectedLanguageSTT, setSelectedLanguageSTT] = useState('en-GB');
  const [isSettingsDialogOpen, setIsSettingsDialogOpen] = useState(false);

  const [messages, setMessages] = useState([]);
  const [error, setError] = useState(null);
  const [isAnswerLoading, setIsAnswerLoading] = useState(false);
  const [query, setQuery] = useState("");

  const [selectedTags, setSelectedTags] = useState([]);

  // Creation of reference for chatbox component to implment automatically scrolling down 
  const chatbox = useRef(null)

  const chatId = useLocation().state?.chatId;
  const chatIdUndefined = "init"
  const [newChatId, setNewChatId] = useState(chatId)

  const navigate = useNavigate();

  // Scroll to newest message if list of messages or attribute isAnswerLoading is updated 
  useEffect(() => chatbox.current.scrollIntoView({ behavior: "smooth", block: "end" }), [messages, isAnswerLoading])

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  function getMessageInit (chatId) {

    console.log("ChatID is :", chatId)
    const endpoint = `/getMessagesInit`;

    api.post(endpoint, {
      body: {"chatId": chatId},
      mode: 'cors'
    }
    ).then((response) => {
      console.log("response: ", response)
      setIsAnswerLoading(false);

      if (response.data.messages === undefined || response.data.messages === []) {
        console.log("Error: Response from /getMessagesInit empty messages")
        setError("Error loading chat, please reload page.");
      } else {
        setMessages(response.data.messages)
        sessionStorage.setItem('reviewId', response.data.reviewId)
      }
    }).catch((exception) => {
      console.log("Error: Response from /getMessagesInit: ", exception)
      setError("Error loading chat, please reload page.");
    });
  }

  // Load the chat automatically when the page loads
  useEffect(() => {
    console.log("Chat id is in useEffect Chatbot: ", chatId)
    if (chatId) {
      getMessageInit(chatId)
    } else {
      getMessageInit("init")
    }
  }, [chatId, url]);

  // Handle Reset History
  const handleResetHistory = (event) => {
    const endpoint = `/getMessagesInit`;

    // Get initial messages
    api.post(endpoint, {
      body: {"chatId": chatIdUndefined},
      mode: 'cors'
    }
    ).then((response) => {
      if (response.data.messages === undefined || response.data.messages === []) {
        console.log("Error: Response from /getMessagesInit empty messages")
        setError("Error loading chat, please reload page.");
      } else {
        setMessages(response.data.messages)
        sessionStorage.setItem('reviewId', response.data.reviewId)

        // Update history in back-end
        const data_update = {
          "id" : chatId,
          "messages" : response.data.messages
        }
        api.post(url + "/chatHistory/updateChatHistoryRecord", {
            headers: {
              'Content-Type': 'application/json'
            },
            data: data_update,
            mode: 'cors'
          }).then((response) => {
            console.log("Updated chat history: ", response)
          }).catch((exeption) => {
            if (exeption.response.status === 400) {
              console.log("Error: Response from /chat empty messages")
              setError("Error while querying Chatbot");
            }
          });
      }
    }).catch((exception) => {
      console.log("Error: Response from /getMessagesInit: ", exception)
      setError("Error loading chat, please reload page.");
    });
  };

  // Handle send new query to chatbot
  const handleQuery = (event, text, llm) => {
    setQuery("")
    console.log("Chat id in handleQuery: ", chatId)

    // Add new query to messages 
    if (messages === null && messages === undefined) {
      setMessages([{ "role": "user", "content": text }]);
    } else {
      messages.push({ "role": "user", "content": text })
    }

    // setIsAnswerLoading to true so laoding message is rendered 
    setIsAnswerLoading(true);

    const data = {
      "query": text,
      "llm": llms[llm].name,
      "messages": messages,
      "tags": selectedTags,
    }

    api.post(url + "/chat", {
      body: data,
      mode: 'cors'
    }).then((response) => {
      console.log("/chat response: ", response)
      // setIsAnswerLoading to false so laoding message is NOT rendered anymore 
      setIsAnswerLoading(false);
      if (response.data.messages === undefined || response.data.messages === 0) {
        console.log("Error: Response from /chat empty messages")
        setError("Reponse from Chatbot is empty")
      } else {
        console.log(response.data.messages)
        setMessages(response.data.messages)
        console.log("chatId: ", chatId)

        // Check if this chat already exists in back-end
        if (chatId === undefined || chatId === chatIdUndefined) {
          // Create new chat
          console.log("Creating a new chat because chatId is ", chatId)
          
          const formData_new = new FormData();
          const chat_new = {
            'messages' : response.data.messages
          }
          formData_new.append('chat_info', JSON.stringify(chat_new));

          api.post('/chatHistory/addChatHistoryRecord', formData_new, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          }).then((response_new_chat) => {
            console.log(`Successfully created new chat ${chat_new.chatName} with ID ${response_new_chat.data.chatId}`)
            if (response_new_chat.data.chatId === undefined) {
              console.log("Did not find a valid Id")
              // TODO: Display error to user
            }
            else {
              setNewChatId(response_new_chat.data.chatId)
              let key = response_new_chat.data.chatId
              console.log("ID of newly created chat: ", newChatId)
              console.log("Changing to: ", key)

              // Navigate to newly created chat
              navigate("/chat", {state: { chatId: key}});
            }

          }).catch((exception) => {
            console.log(exception)
          });
          } else {
          // Update history
          const data_update = {
            "id" : chatId,
            "messages" : response.data.messages
          }
          api.post(url + "/chatHistory/updateChatHistoryRecord", {
            headers: {
              'Content-Type': 'application/json'
            },
            data: data_update,
            mode: 'cors'
          }).then((response) => {
            console.log("response: ", response)
            // setIsAnswerLoading to false so laoding message is NOT rendered anymore 
            setIsAnswerLoading(false);
          }).catch((exeption) => {
            // setIsAnswerLoading to false so laoding message is NOT rendered anymore
            setIsAnswerLoading(false);
            if (exeption.response.status === 400) {
              console.log("Error: Response from /chat empty messages")
              setError("Error while querying Chatbot");
            }
          });
        }

        

      }
    }).catch((exeption) => {
      // setIsAnswerLoading to false so laoding message is NOT rendered anymore
      setIsAnswerLoading(false);
      if (exeption.response.status === 400) {
        console.log("Error: Response from /chat empty messages")
        setError("Error while querying Chatbot");
      }
    });

    
  };

  const handleMicOn = (event) => {
    resetTranscript()
    SpeechRecognition.startListening({ continuous: true, language: selectedLanguageSTT })
  };

  const handleMicOff = (event) => {
    SpeechRecognition.stopListening();
    setQuery(query + transcript)
    resetTranscript()
  };

  const handleChangeLanguage = (event) => {
    setSelectedLanguageSTT(event.target.value);
  };

  const handleChangeLLM = (event) => {
    setLLM(event.target.value);
  };

  const handleOpenSettings = () => {
    SpeechRecognition.stopListening();
    resetTranscript()
    setIsSettingsDialogOpen(true);
  };

  const handleCloseSettingsDialog = (event, reason) => {
    if (reason !== 'backdropClick') {
      setIsSettingsDialogOpen(false);
    }
  };

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  return (
    <Box className="chat-main-container">
      <Box sx={{ flexGrow: 1, overflow: "auto", p: 2 }} >
        
        {messages && messages.map((message, index) => (
          <Message key={message.id} message={message} lastMessage={messages[index - 1]} messages={messages} />
        ))}
        {isAnswerLoading && <PendingMessage></PendingMessage>}
        <div ref={chatbox} />
      </Box>
      <Box sx={{
        flexDirection: "column",
        alignItems: 'center',
        display: 'flex'
      }} >
        <EnhancedQueryBar
          query={query} setQuery={setQuery} handleQuery={handleQuery}
          listening={listening} handleMicOff={handleMicOff} handleMicOn={handleMicOn}
          transcript={transcript} resetTranscript={resetTranscript}
          isSettingsDialogOpen={isSettingsDialogOpen} handleOpenSettings={handleOpenSettings} handleCloseSettingsDialog={handleCloseSettingsDialog}
          handleChangeLanguage={handleChangeLanguage} selectedLanguageSTT={selectedLanguageSTT}
          llm={llm} handleChangeLLM={handleChangeLLM} handleResetHistory={handleResetHistory}
          selectedTags={selectedTags} setSelectedTags={setSelectedTags}/>
      </Box>
      <div>
        {error && <ErrorBanner message={error} errorSetter={setError} />}
      </div>
    </Box>
  );
};

const Message = ({ message, lastMessage, messages }) => {
  const isSystem = message.role === "system";
  const isBot = message.role === "assistant";

  // do not render system prompt
  if (isSystem) {
    return (
      <div />
    )
  }

  const renderTextWithLineBreaks = (elements) => {
    return elements.map((element, index) => {
      // Check if the element is a string
      if (typeof element === 'string') {
        // Split the string by newlines and map to React fragments
        const lines = element.split('\n');
        return lines.map((line, lineIndex) => (
          <React.Fragment key={`line-${index}-${lineIndex}`}>
            {line}
            {lineIndex !== lines.length - 1 && <br />}
          </React.Fragment>
        ));
      } else if (element && element.$$typeof === Symbol.for('react.element')) {
        // If the element is a React element, render it directly
        return React.cloneElement(element, { key: `element-${index}` });
      } else {
        // If the element is neither a string nor a React element, return null or a placeholder
        return <React.Fragment key={`unknown-${index}`}></React.Fragment>;
      }
    });
  };

  const replaceTagsWithLinks = (text) => {
    let pattern = /(\[\d+\])/;
    const parts = text.split(pattern).filter(Boolean);

    return parts.map((part, index) => {
      const match = part.match(/\[(\d+)\]/);
      if (match) {
        return (
          <Link
            key={index}
            component="span"
            variant="body2"
            onClick={() => {
              let url = '/chatSource'
              let hash1 = storeStringAndGetHash(lastMessage.content)
              if (hash1) {
                url += "?query=" + hash1
              }
              let hash2 = storeStringAndGetHash(message.content)
              if (hash2) {
                url += "&message=" + hash2
              }
              let hash3 = storeObjectAndGetHash(message.sources)
              if (hash3) {
                url += "&source=" + hash3
              }
              window.open(url, '_blank')
            }
            }
            color="primary"
          >
            {part}
          </Link>
        );
      } else {
        return part;
      }
    });
  }

  return (
    <Box className="chat-message-container"
      sx={{
        justifyContent: isBot ? "flex-start" : "flex-end",
      }}
    >
      {isBot && <SupportAgentIcon sx={{ fontSize: "50px" }} size="large" color="secondary" />}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column', // Stack Paper and Link vertically
          alignItems: isBot ? 'flex-start' : 'flex-end', // Align based on isBot
          flex: 1, // Take up remaining space
          marginLeft: isBot ? 0 : 'auto', // Push content to the right if isBot is false
          marginRight: isBot ? 'auto' : 0, // Push content to the left if isBot is true
        }}
      >
        <Paper
          className="chat-message"
          component="form"
          elevation={4}
          sx={{
            backgroundColor: isBot ? "secondary.light" : "primary.light"
          }}
        >
          <Typography color={isBot ? "#FFFFFF" : "#000000"} paddingRight="15px" paddingLeft="15px" align="left">
            {renderTextWithLineBreaks(replaceTagsWithLinks(message.content))}
          </Typography>
        </Paper>
        {isBot && !message.content.startsWith("Welcome!") &&
          <Stack
            direction="row"
            spacing={2}
            style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginLeft: "50px", marginBottom: "10px" }}>
            <FeedBackStack message={message} messages={messages} />
            <Divider orientation='vertical'  variant="fullWidth" textAlign="left" 
            sx={{ color: "primary.main", borderWidth: 1, marginTop: "-20px !important", height:"25px", marginLeft:"15px !important"}} />
            <Link
              component="span"
              variant="body2"
              onClick={() => {
                let url = '/chatSource'
                let hash1 = storeStringAndGetHash(lastMessage.content)
                if (hash1) {
                  url += "?query=" + hash1
                }
                let hash2 = storeStringAndGetHash(message.content)
                if (hash2) {
                  url += "&message=" + hash2
                }
                let hash3 = storeObjectAndGetHash(message.sources)
                if (hash3) {
                  url += "&source=" + hash3
                }
                window.open(url, '_blank')
              }
              }
              color="primary"
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                color: "primary.main",
                marginTop: "-15px", // Space above the link
                marginLeft: '25px', // Add space to the left of the link
                alignSelf: 'flex-start' // Align the link to the start for bot messages
              }}
            >
              Want to know more about this answer?
            </Link>
          </Stack>
        }

      </div>
      {!isBot && <PersonIcon sx={{ fontSize: "50px" }} size="large" color="primary" />}
    </Box>
  );
};

const PendingMessage = () => {
  return (
    <Box className="chat-message-container"
      sx={{
        justifyContent: "flex-start",
      }}
    >
      <SupportAgentIcon sx={{ fontSize: "50px" }} size="large" color="secondary" />
      <Paper
        className="chat-message"
        component="form"
        elevation={4}
        sx={{
          p: 1,
          backgroundColor: "secondary.light"
        }}
      >
        <Typography
          color={"#FFFFFF"} paddingRight="15px" paddingLeft="15px" >Generating answer...</Typography>
        <CircularProgress color="primary" />
      </Paper>
    </Box>
  );
};

export default Chatbot;