import React, {useState} from "react";
import { Backdrop, Box, CircularProgress, Link, Paper, Stack, Typography } from '@mui/material';
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import styled from "@emotion/styled";
import ErrorBanner from '../features/errorBanner/errorBanner';
import {PdfViewer } from "../features/PdfViewer";
import '../features/PdfViewer.css';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import api from "../config/axiosConfigs";
import { EnhancedQueryBar } from '../features/queryBar/EnhancedQueryBar';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

function SingleDocumentSearch() {

  const navigate = useNavigate();
  const [query, setQuery] = useState(null);
  const [selectedPdfUrl, setSelectedPdfUrl] = useState(null);
  const [pdfViewerDisplay, setPdfViewerDisplay] = useState('none');
  const [llmResultAnswer, setLlmResultAnswer] = useState(null);
  const [llmResultSources, setLlmResultSources] = useState(null);
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedPageSource, setSelectedPageSource] = useState(null);
  const [selectedLanguageSTT, setSelectedLanguageSTT] = useState('en-GB');
  const [isSettingsDialogOpen, setIsSettingsDialogOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]); 

  const Item = styled(Paper)(({}) => ({
    backgroundColor: "#fff",
    border: "0 solid #e5e7eb",
    padding: 10,
    textAlign: "center",
  }));

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  const [llm, setLLM] = useState(0);
  const llms = [
    {
      label: "OpenAI (GPT-3.5-Turbo-16k)",
      name: "openai/gpt-3.5-turbo-16k"
    },
    {
      label: "OpenAI (GPT4)",
      name: "openai/gpt-4"
    },
    {
      label: "Nemo (GPT20B)",
      name: "nemo/gpt20b"
    },
    {
      label: "Nemo (GPT-43B-001)",
      name: "nemo/gpt-43b-001"
    },
    {
      label: "Nemo (GPT-43B-002)",
      name: "nemo/gpt-43b-002"
    },
  ]

  const handleResetHistory = (event) => {
  }

  const handleQuery = (event, text, llm) => {
    setIsUploadLoading(true)
    setSelectedPdfUrl(null)
    const data = {
      "query": query,
      llm: llms[llm].name,
      "tags": selectedTags
    }

    api.post("/search", {
      body: data,
      mode: 'cors'
    }
    ).then((response) => {
      setIsUploadLoading(false)
      if (response.data.answer === undefined || response.data.answer.length === 0) {
        setLlmResultAnswer("Sorry, but I couldn't generate a meaningful response for your query. Please try rephrasing your question or providing more context so I can better assist you.")
      } else {
        console.log()
        setLlmResultAnswer(response.data.answer)
      }

      if (response.data.sources !== undefined && response.data.sources.length > 0) {
        setLlmResultSources(response.data.sources)
      }
    }).catch((exeption) => {
      setIsUploadLoading(false)
      if(exeption.response?.status === 401){
        setError("Session expired");
        navigate('/login',  {
          state:{
            error: "Session expired, please login"
          }
        });
      }
      if (exeption.response?.status === 400) {
        setError("Query was empty!");
      }
      setError("Connection timed out")
    });
  }

  const handleMicOn = (event) => {
    resetTranscript()
    SpeechRecognition.startListening({ continuous: true, language: selectedLanguageSTT })
  };

  const handleMicOff = (event) => {
    SpeechRecognition.stopListening();
    setQuery(query + transcript)
    resetTranscript()
  };

  const handleChangeLanguage = (event) => {
    setSelectedLanguageSTT(event.target.value);
  };

  const handleChangeLLM = (event) => {
    setLLM(event.target.value);
  };

  const handleOpenSettings = () => {
    SpeechRecognition.stopListening();
    resetTranscript()
    setIsSettingsDialogOpen(true);
  };

  const handleCloseSettingsDialog = (event, reason) => {
    if (reason !== 'backdropClick') {
      setIsSettingsDialogOpen(false);
    }
  };

  const maxWidth = 1400;

  function get_pdf_url(classname, document, page, start_line, end_line) {
    const data = {"classname" : classname, "document": document, "page": page, "start_line": start_line, "end_line": end_line}
    api.post("/getHighlightedDocument", {
      body: data,
      mode: 'cors'
    }).then((response) => {
      if (response.data.link === undefined) {
        setError("Failed to load highlighted PDF")
        setSelectedPdfUrl(null)
      } else {
        setSelectedPdfUrl(response.data.link);
        setSelectedPageSource(page);
      }
    }).catch((exception) => {
      setError("Failed to load highlighted PDF")
      setSelectedPdfUrl(null)
    });
  }

  const renderTextWithLineBreaks = (elements) => {
    return elements.map((element, index) => {
      // Check if the element is a string
      if (typeof element === 'string') {
        // Split the string by newlines and map to React fragments
        const lines = element.split('\n');
        return lines.map((line, lineIndex) => (
          <React.Fragment key={`line-${index}-${lineIndex}`}>
            {line}
            {lineIndex !== lines.length - 1 && <br />}
          </React.Fragment>
        ));
      } else if (element && element.$$typeof === Symbol.for('react.element')) {
        // If the element is a React element, render it directly
        return React.cloneElement(element, { key: `element-${index}` });
      } else {
        // If the element is neither a string nor a React element, return null or a placeholder
        return <React.Fragment key={`unknown-${index}`}></React.Fragment>;
      }
    });
  };

  function renderTextWithLinks(textWithLinks, llmResultSources) {
      const pattern = /(\[\d+\])/;
      const parts = textWithLinks.split(pattern).filter(Boolean);

      // Convert llmResultSources to a map for easier access
            const sourcesMap = new Map(llmResultSources.map(source => [source.id, source]));

      return parts.map(part => {
          const match = part.match(/\[(\d+)\]/);
                              if (match) {
              const sourceId = match[1];
              const source = sourcesMap.get(sourceId);

              if (!source) {
                  // If no source is found for the given ID
                  return "";
              } else {
                  const { classname, document, page, start_line, end_line } = source;
                  return (
                      <Link
                          component="button"
                          variant="body2"
                          onClick={() => get_pdf_url(classname, document, page, start_line, end_line)}
                          color="primary"
                      >
                          {part}
                      </Link>
                  );
              }
          } else {
              // Otherwise, return the plain text part.
              return part;
          }
      });
  }

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", marginTop: "100px" }}>
      {/* Loading Backdrop */}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isUploadLoading}
      >
        <div direction="column">
          <CircularProgress color="inherit" />
          <Typography>Loading</Typography>
        </div>
      </Backdrop>
        {/* Main Content */}
        <Box sx={{
          marginTop: "10px",
          display: "flex",
          width: "100%",
          overflow: "hidden",
          alignContent: "center",
          alignItems: "flexStart",
          justifyContent: "center",
          overflow: 'visible'
        }}>

          {/* Left Side Content */}
          <Stack direction="column" justifyContent="center" alignItems="center" spacing={2} sx={{ maxWidth: maxWidth, width: "100%", p: 1, m: 1}}>

            {/* Query Component */}
            <Box sx={{
              display: 'flex',
              flexDirection: "column",
              alignItems: 'center',
              maxWidth: maxWidth, 
              width: "100%",
            }} >
              <EnhancedQueryBar
                query={query} setQuery={setQuery} handleQuery={handleQuery}
                listening={listening} handleMicOff={handleMicOff} handleMicOn={handleMicOn}
                transcript={transcript} resetTranscript={resetTranscript}
                isSettingsDialogOpen={isSettingsDialogOpen} handleOpenSettings={handleOpenSettings} handleCloseSettingsDialog={handleCloseSettingsDialog}
                handleChangeLanguage={handleChangeLanguage} selectedLanguageSTT={selectedLanguageSTT}
                llm={llm} handleChangeLLM={handleChangeLLM} handleResetHistory={handleResetHistory} 
                selectedTags={selectedTags} setSelectedTags={setSelectedTags}/>
            </Box>
          
            {/* Answer Component */}
            {llmResultSources && (<Stack direction="column" spacing={2} sx={{ maxWidth: maxWidth, width: "100%", padding: 2 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignContent: "left",
                  bgcolor: 'background.paper',
                  borderRadius: 1,
                }}
              >
                <AutoAwesomeIcon size="large" color="primary" sx={{ paddingRight: 2 }}></AutoAwesomeIcon>
                <Typography><strong>Answer</strong></Typography>
              </Box>
              <Typography variant="body2" align="left">
                {llmResultAnswer && renderTextWithLineBreaks(renderTextWithLinks(llmResultAnswer, llmResultSources))}
                {/* {dummyAnswer.answer} */}
              </Typography>
            </Stack>)}

            {/* Sources Component */}
            {llmResultSources && (<Stack direction="column" spacing={2} sx={{ maxWidth: maxWidth, width: "100%", padding: 2 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignContent: "left",
                  bgcolor: 'background.paper',
                  borderRadius: 1,
                }}
              >
                <AutoAwesomeIcon color="primary" sx={{ paddingRight: 2 }}></AutoAwesomeIcon>
                <Typography><strong>Sources</strong></Typography>
              </Box>
              <div style={{ width: "100%" }}>
                <Box sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, minmax(0,1fr))",
                  gap: "20px"
                }}>
                  {llmResultSources && llmResultSources.map((citation) => (
                    <Item elevation={4} sx={{ alignItems: "left" }}>
                      <Typography align="left"><strong>{citation.id}</strong> - {citation.document}, {citation.info_text}</Typography>
                      <Tooltip title={
                          <small>
                            Cosine semantic similarity <br /> score of context
                          </small>
                        } arrow placement="bottom">
                        <div style={{maxWidth: "100px"}}>
                          <Typography align="left" color={"green"}>Score: {citation.score}</Typography>
                        </div>
                      </Tooltip>
                    </Item>
                  ))}
                </Box>
              </div>
            </Stack>)}
          </Stack>
        {/* Right Side Content */}
        {selectedPdfUrl && (
          <Box 
            sx={{ 
              p: 1, 
              m: 1,
              minWidth: "50%",
              overflow: "auto",
              display: `${pdfViewerDisplay}`              
              }}>
          <div className="pdfViewer" sx={{minHeight: "50%", overflow: "auto"}}>
            {selectedPdfUrl && <PdfViewer 
                                  pdfUrl={selectedPdfUrl}
                                  selectedPage={selectedPageSource} 
                                  setError={setError} 
                                  setPdfViewerDisplay={setPdfViewerDisplay} 
                                  setSelectedPdfUrl={setSelectedPdfUrl}
                                />}
          </div>
        </Box>)}
        </Box>
      <div>
        {error && <ErrorBanner message={error} errorSetter={setError} />}
      </div>
    </div>
  )
}

export default SingleDocumentSearch;