import { React, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import deloitte_logo from "../../src/deloitte_logo.png";
import ErrorBanner from '../features/errorBanner/errorBanner';
import './Login.css';
import api from '../config/axiosConfigs';

function Login({ loginSetter }) {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const handleLoginClick = (e) => {
    e.preventDefault(); //stop the browser from doing anything
    api.post("/login", {
      data: {
        "username": userName,
        "password": password
      }
    }).then((response) => {
      Cookies.set('sessionToken', response.data.token);
      window.localStorage.setItem('loggedIn', true); //this is needed to show the App Drawer
      loginSetter(true);
      //this is need when a reroute from any page to login happens
      //in that case we need to set the display of the whole component, again in the catch statement
      hideAppDrawerIfNotLoggedIn(false); 
      navigate('/DocumentManagement');
    }).catch((ex) => {
      let message = ex.response?.data.message 
      setError(message? message : "Failed to connect to the backend");
      hideAppDrawerIfNotLoggedIn(true);
    });
  };

  useEffect(() => {
    //When /login page is visited, we check if the token in the cookie is still valid
    const errorMessage = location.state && location.state.error;
    if (errorMessage){
      setError(errorMessage);
    }
    api.get("/verifytoken").then(()=>{
      hideAppDrawerIfNotLoggedIn(false);
      navigate('/DocumentManagement');
    }).catch((ex)=>{
      console.log(ex);
      hideAppDrawerIfNotLoggedIn(true);
    })
  }, []);

  return (
    <div className="login-container">
      <div className="Deloitte-Logo">
        <img src={deloitte_logo} alt="This is the deloitte logo" style={{ height: '100px' }} />
      </div>
      <div className="box">
        <h2 style={{ color: "#86BC25" }}>Doc Insights</h2>
        <form>
          <div className="form-group">
            <label className='label-login'>Username</label>
            <input type="username" placeholder="Username" className='input-login' onChange={(event) => {
              setUserName(event.target.value);
            }} autoComplete="username" />
          </div>
          <div className="form-group">
            <label className='label-login'>Password</label>
            <input type="password" placeholder="Password" className='input-login' onChange={(event) => {
              setPassword(event.target.value);
            }} autoComplete="password" />
          </div>
          <button className='login-button' type="submit" onClick={handleLoginClick} style={{ backgroundColor: "#43B02A", marginTop: "20px" }}>Log In</button>
        </form>
      </div>
      {error && <ErrorBanner message={error} errorSetter={setError} />}
    </div>
  );
}

function hideAppDrawerIfNotLoggedIn(hidden){
  let display = hidden? 'none' : 'block';
  
  var appDrawerButton = document.getElementById('App-drawer-button');
  var appDrawer = document.getElementById('App-drawer');

  appDrawerButton && (appDrawerButton.style.display = display);
  appDrawer && (appDrawer.style.display = display);
}

export default Login;
